import React from 'react';
import Footer from '../Footer/Footer';
import CommonPage from './CommonPage';

function Othersport() {
    return (
        <>
            <div className="container" id="Planning">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <h1 className="font-weight-bold"><b>For the love of developing athletes
                        </b> </h1>
                        <h5>Get your free ALIBTISAM  features overview</h5>
                        {/* <button type="button" className="btn btn-success"><b>Download
                            PDF</b><i className="fa fa-2x fa-file-o" aria-hidden="true"></i>
                        </button> */}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                    <img src="images/basketball.jpg" alt="image1" width='400' className='mt-5 img-fluid'/>
                    </div>
                </div>
            </div>
            
            <CommonPage />

            {/* <div className='container text-center'>
                <h1><b>We help teams succeed.</b></h1>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h4><b>US Soccer Women's World Champions National Team</b></h4>
                        <p>“XPS is a comprehensive planning, organizing, and communication tool we used daily with our players and staff.”</p>
                         <img className='rounded-circle' width='50' src={jelliscoachImage} alt='Jeroen Baart Image' />
                        <h6><b>Jill Ellis</b></h6>
                        <span>Head Coach, World Champion 2019</span>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h4><b>Athletics</b></h4>
                        <p>“XPS is a top quality software. I recommend it for all trainers.”</p>
                         <img className='rounded-circle' width='50' src={vestiennhafscoachImage} alt='Jorge Dabanch Image' />
                        <h6><b>Vesteinn Hafsteinsson</b></h6>
                        <span>Coach of World and Olympic Champions</span> 
                    </div>
                </div>
            </div> */}
            
            <Footer />
        </>
    )
}

export default Othersport