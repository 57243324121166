import React, { useState, useEffect } from 'react';
import './home.css';
import Footer from '../Footer/Footer';
import { FcCheckmark } from "react-icons/fc";
import video2 from '../../Images/fp-02_communication.mp4';
import video3 from '../../Images/fp-03_monitoring.mp4';
import video4 from '../../Images/fp-04_tactics_and_analysis.mp4';
import video5 from '../../Images/fp-05_XPS_App.mp4';
import qrCodeImage from '../../Images/qrcode.png';
import appleStoreImage from '../../Images/applestore.png';
import googleStoreImage from '../../Images/googlestore.png';
import image1 from '../../Images/1.jpg';
import image2 from '../../Images/2.jpg';
import image3 from '../../Images/3.jpg';
import image4 from '../../Images/4.jpg';
import image5 from '../../Images/5.jpg';
import image6 from '../../Images/6.jpg';
import image7 from '../../Images/7.jpg';
import image8 from '../../Images/8.jpg';





const imageData = [
    {
        id: 1,
        image: image1
    },
    {
        id: 2,
        image: image2
    },
    {
        id: 3,
        image: image3
    },
    {
        id: 4,
        image: image4
    },
    {
        id: 5,
        image: image5
    },
    {
        id: 6,
        image: image6
    },
    {
        id: 7,
        image: image7
    },
    {
        id: 8,
        image: image8
    },
    
]



function Home() {

    const [randomImage, setRandomImage] = useState(null);

    useEffect(() => {
        const getRandomImage = () => {
            const randomIndex = Math.floor(Math.random() * imageData.length);
            return imageData[randomIndex].image;
        };

        setRandomImage(getRandomImage());
    }, []);
    return (
        <>
            <div className='container-fluid '>
                <div className='row align-items-center'>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
                        <h1><b>We power your <br /> performance</b></h1>
                        <h5 className='mt-3'><b>Complete software solution for modern clubs and coaches</b></h5>

                        <h5><b><FcCheckmark />Monitor and analyze all training data</b></h5>
                        <h5><b><FcCheckmark />Make data-driven coaching decisions</b></h5>
                        <h5><b><FcCheckmark />Give instant feedback to your players</b></h5>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        {randomImage ? (
                            <img src={randomImage} alt="Random" width='350' height='300' className='mt-5 img-fluid'/>
                        ) : (
                            <p>Loading...</p>
                        )}

                    </div>
                </div>
            </div>
            <div className='container-fluid mtop' >
                <div className='row justify-content-between align-items-center '>
                    <div className='col-lg-6 col-md-6 col-sm-12 pe-5 '>
                        <h1><b>You need a powerful solution for your athletes to excel</b></h1>
                        <p><b>Save time and keep everything in one place</b></p>
                        <h5><b>Communicate with your athletes</b></h5>
                        <hr />
                        <h5><b>Analyze performance and training</b></h5>
                        <hr />
                        <h5><b>Create high levels of accountability and feedback</b></h5>
                        <hr />
                        <h5><b>Build your lifetime of coaching observation</b></h5>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 '>
                        <img src="images/dashboard.jpeg" alt='products' width='550' className="img-fluid" />
                    </div>
                </div>
            </div>

            <div className='container-fluid mtop'>
                <div className='row align-items-center'>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
                    <img src="images/planning.png" alt='image' width='500'className="img-fluid" />

                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
                        <h1 className='mb-5'><b>Organization & Planning</b></h1>
                        <h5 className='mt-5'><b>Build your curriculum based on your coaching philosophy</b></h5>
                        <hr />
                        <h5><b>Plan all team and individual training</b></h5>
                        <hr />
                        <h5><b>Get a complete overview of what's happening in your club</b></h5>
                    </div>
                </div>
            </div>

            <div className='container-fluid color mtop'>
                <div className='row align-items-center'>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
                        <h1 className='mb-5'><b>Communication</b></h1>
                        <h5 className='mt-5'><b>Share all preparations for match and training</b></h5>
                        <hr />
                        <h5><b>Give instructions impossible to misunderstand</b></h5>
                        <hr />
                        <h5><b>Keep all communication in one place</b></h5>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
                    <img src="images/communication.png" alt='image' width='400' height='300' className="img-fluid"/>
                    </div>
                </div>
            </div>

            <div className='container-fluid mtop'>
                <div className='row align-items-center'>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
                    <img src="images/monitoring1.png" alt='image' width='400' height='300' className="img-fluid"/>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
                        <h1 className='mb-5'><b>Monitoring</b></h1>
                        <h5 className='mt-5'><b>Analyze performance and training data</b></h5>
                        <hr />
                        <h5><b>Monitor readiness, wellness and training load</b></h5>
                        <hr />
                        <h5><b>Make data-driven coaching decisions</b></h5>
                    </div>
                </div>
            </div>

            {/* <div className='container-fluid color'>
                <div className='row align-items-center'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h1 className='mb-5'><b>Communication</b></h1>
                        <h5 className='mt-5'><b>Share all preparations for match and training</b></h5>
                        <hr />
                        <h5><b>Give instructions impossible to misunderstand</b></h5>
                        <hr />
                        <h5><b>Keep all communication in one place</b></h5>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <video autoPlay muted loop playsinline preload="metadata" width='550'>
                            <source src={video4} type="video/mp4" />
                        </video>
                    </div>
                </div>
            </div> */}

            {/* <div className='container-fluid'>
                <div className="row py-5">
                    <div>
                        <div className="card-body text-center ">
                            <h1 className='mb-4'><b>The complete software solution for coaches in all sports</b></h1>
                            <div className="row text-center justify-content-center">
                                <div className="col-auto">
                                    <div className="input-group-append">
                                        <button className="btn btn-success mt-4" type="button" id="button-addon2">         <b>TRY ALIBTISAM FOR FREE</b>
                                        </button>
                                        <p className="pl-0 ml-0 mt-4">No credit card required.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

{/* Watch ALIBTISAM  App Overview section start */}
            {/* <div className='container-fluid color mt-5'>
                <div className='container pt-5 text-center mt-5'>
                    <h1><b>Always with you</b></h1>
                    <h6><b>Watch ALIBTISAM  App Overview</b></h6>
                </div>
                <div className='d-flex align-items-center justify-content-center mt-5' >
                    <video autoPlay muted loop playsinline preload="metadata" width='800'>
                        <source src={video5} type='video/mp4' />
                    </video>
                </div>



                <div className='row align-items-center '>
                    <div className='col-lg-6 col-md-6 col-sm-12'> 
                        <img src={qrCodeImage} alt='Image' />
                        <a href='/'>
                            <img src={appleStoreImage} alt='Image' width='400' height='120' />
                        </a>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'> 
                        <img src={qrCodeImage} alt='Image' />
                        <a href='/'>
                            <img src={googleStoreImage} alt='Image' width='400' />
                        </a>
                    </div>
                </div>
            </div> */}

            {/* Watch ALIBTISAM  App Overview section end */}


{/* portfolio section */}
            {/* <div className="container portfolio">
                <div className="bio-info">
                    <div className="row">
                        <div className="col-md-6">
                            <div clasNameclassNames="row">
                                <div className="col-md-12">
                                    <div className="bio-image">
                                        <img src="https://image.ibb.co/f5Kehq/bio-image.jpg"  alt="image" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="bio-content">
                                <h1>Hi there, I'm Kshiti</h1>
                                <h6>I am a fresh web designer and I create custom web designs. I'm skilled at writing well-designed, testable and efficient code using current best practices in Web development. I'm a fast learner, hard worker and team player who is proficient in making creative and innovative web pages.</h6>
                                <p>P.S I have no special talent, I'm just passionately curious ;</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <Footer />
        </>
    )
}

export default Home; 