import React, {useState, useEffect} from "react";
import "./LoginForm.css"
import { Link } from 'react-router-dom';




const Admin = () => {


  

  // React States
  const [errorMessages, setErrorMessages] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  // User Login info
  const database = [
    {
      username: "user1",
      password: "pass1"
    },
    {
      username: "user2",
      password: "pass2"
    }
  ];

  const errors = {
    uname: "invalid username",
    pass: "invalid password"
  };

  const handleSubmit = (event) => {
    //Prevent page reload
    event.preventDefault();

    var { uname, pass } = document.forms[0];

    // Find user login info
    const userData = database.find((user) => user.username === uname.value);

    // Compare user info
    if (userData) {
      if (userData.password !== pass.value) {
        // Invalid password
        setErrorMessages({ name: "pass", message: errors.pass });
      } else {
        setIsSubmitted(true);
      }
    } else {
      // Username not found
      setErrorMessages({ name: "uname", message: errors.uname });
    }
  };

  // Generate JSX code for error message
  const renderErrorMessage = (name) =>
    name === errorMessages.name && (
      <div className="error">{errorMessages.message}</div>
    );

  // JSX code for login form
  const renderForm = (
    <div className="form">
      <form onSubmit={handleSubmit}>
        <div className="input-container">

          < input type="text" name="uname"  placeholder=" &ensp; Username" required />
          {renderErrorMessage("uname")}
        </div>
        <div className="input-container">
          <input type="password" name="pass" placeholder=" &ensp; Password" required />
          {renderErrorMessage("pass")}
        </div>
        <div className="input-container ">

        <button type="submit" className="signinbutton py-1 ">SIGN IN</button>
          
        </div>
                {/* button group strat */}

                <div class="btn-group  d-flex justify-contnet-center bg-white mt-3 mx-auto " role="group" aria-label="Basic example">
  <button type="button" class="btn btn btn-sm btncolor fsize" >
  <Link to='/SuperAdmin' href="/" className="linkstyle"><b>Super Admin</b></Link></button>


  <button type="button" class="btn btn btn-sm  btncolor  fsize" style={{ background: "#b2b2ea" }} >
  <Link to='/Admin' href="/" className="linkstyle" ><b>Admin</b></Link></button>


  <button type="button" class="btn btn btn-sm  btncolor fsize " >
  <Link to='/Coach' href="/"className="linkstyle"><b>Coach</b></Link></button>
</div>
      </form>
      <p className="text-white mt-2 text-center fsize">all Copyrights @ALIBTISAM </p>
    </div>
  );

  return (
    <div className="loginbox">
      <div className="login-form">
      <div className=" d-flex justify-content-center  ">
            <h6 className="text-white mt-4  ">ALIBTISAM <br/><span className="mx-5">الابتسام</span> </h6>

            <img src="images/icon.png" alt="image" srcset="" width="80" height="80" className="roundedimage "/>
        </div>

        <div className="title text-center text-white ">Admin</div>
        <hr className="text-white"></hr>
        {isSubmitted ? <div className="text-white">Admin is successfully logged in</div> : renderForm}
      </div>
    </div>
  );
}


export default Admin;
