import React from 'react';

function CommonPage() {
  return (
    <>
    {/* ALIBTISAM in 30 Second app video section start*/}

      {/* <div className='container mt-5'>
        <div className='row text-center'>
          <h1>ALIBTISAM in 30 Second</h1>
          <div className='col-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-content-center'>
            <img src={videoPoster} alt='Video Poster' width='700' />
          </div>
        </div>
      </div> */}

    {/* ALIBTISAM in 30 Second app video section end*/}

      <div className='container ' style={{marginTop:"100px"}}>
        <h1 className='mb-5 text-center mt-5'><b>Your coaching is continuously improving</b></h1>
        <div className='row align-items-center mt-5'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            {/* <video autoPlay muted loop playsinline preload="metadata" width='500'>
              <source src={coachtacticsVideo} type="video/mp4" />
            </video> */}

<img src= "images/sports planning.png" alt='image' width='350' className="img-fluid"/>

          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <h5 className='mt-5'><b>Plan</b></h5>
            <hr />
            <h5><b>Prepare</b></h5>
            <hr />
            <h5><b>Analyze</b></h5>
          </div>
        </div>
      </div>

      <div className='container ' style={{marginTop:"100px"}}>
        <h1 className='mb-5 text-center'><b>Monitor and improve team and player performance</b></h1>
        <div className='row align-items-center'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <h5 className='mt-5'><b>Choose your indicators</b></h5>
            <hr />
            <h5><b>Create in-depth reports</b></h5>
            <hr />
            <h5><b>Make the right coaching decisions</b></h5>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>

            {/* <video autoPlay muted loop playsinline preload="metadata" width='500'>
              <source src={performanceloadVideo} type="video/mp4" />
            </video> */}
             <img src= "images/Three graph.png" alt='image' width='500' className="img-fluid"/>

          </div>
        </div>
      </div>

      <div className='container mt-5'>
        <h1 className='mb-5 text-center'><b>Create high levels of accountability and feedback</b></h1>
        <div className='row align-items-center'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            {/* <video autoPlay muted loop playsinline preload="metadata" width='500'>
              <source src={streamlineVideo} type="video/mp4" />
            </video> */}
          <img src= "images/feedback.png" alt='image' width='500' className="img-fluid" />

          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <h5 className='mt-5'><b>Streamline your communication</b></h5>
            <hr />
            <h5><b>Share your knowledge</b></h5>
            <hr />
            <h5><b>Give instant feedback</b></h5>
          </div>
        </div>
      </div>
{/* youtube vidoe section start */}

      {/* <div className='container mt-5 mb-5'>
        <div className='row text-center'>
          <h1>ALIBTISAM Stories: Get inspired</h1>
          <div className='col-12 col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center align-content-center'>
            <iframe src="https://www.youtube.com/embed/SVB1tKbhyvs" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen="" width='1000' height='500'></iframe>
          </div>
        </div>
      </div> */}
      {/* youtube vidoe section end */}

      <div className='container'>
        <div className="row py-5">
          <div>
            <div className="card-body text-center ">
              <h1 className='mb-4'><b>The complete software solution for coaches in all sports</b></h1>
              <div className="row text-center justify-content-center">
                <div className="col-auto">
                  <div className="input-group-append">
                    <button className="btn btn-success mt-4" type="button" id="button-addon2"><b>TRY ALIBTISAM FOR FREE</b>
                    </button>
                    <p className="pl-0 ml-0 mt-4">No credit card required.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

 
      <div>
        <div className='text-center'>
          <h4><b>Analyze</b></h4>
          <h1><b>Instant access to statistics</b></h1>
          <p>Analyze clear presentations of results and development. Make data-driven coaching decisions. Let the players see their own development.</p>
        </div>
        <div className='d-flex justify-content-center align-items-center mt-5'>
        <img src="images/statistics.png" alt='Image' width='700' className="img-fluid"/>
        </div>
      </div>



    </>
  )
}

export default CommonPage;