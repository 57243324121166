import React from 'react';
import Footer from '../Footer/Footer';
import CommonPage from './CommonPage';


function Handball() {
  return (
    <>
      <div className="container" id="Planning">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <h1 className="font-weight-bold"><b>For the love of developing athletes
            </b> </h1>
            <h5>Get your free ALIBTISAM  features overview</h5>
            {/* <button type="button" className="btn btn-success"><b>Download
              PDF</b><i className="fa fa-2x fa-file-o" aria-hidden="true"></i>
            </button> */}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <img src="images/hand ball.jpg" alt="image1" width='400' className='mt-5 img-fluid' />
          </div>
        </div>
      </div>
      <CommonPage />

      {/* <div className='container text-center'>
        <h1><b>We help teams succeed.</b></h1>
        <div className='row'>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <h4><b>Women's National Team, Norway</b></h4>
            <p>“Sideline XPS has helped us to be more effective in our preparations. The Sideline XPS products are easy to use but still very powerful.”</p>
            <img className='rounded-circle' width='50' src={macculamCoach} alt='Jeroen Baart Image' />
            <h6><b>Thorir Hergeirsson</b></h6>
            <span>Head Coach</span>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <h4><b>IFK Kristianstad</b></h4>
            <p>“I place high demands on my coaching software and Sideline Sports meets them all.”</p>
            <img className='rounded-circle' width='50' src={macculamCoach} alt='Jorge Dabanch Image' />
            <h6><b>Ljubomir Vranjes</b></h6>
            <span>Head Coach</span>
          </div>
        </div>
      </div> */}


      <Footer />
    </>
  )
}

export default Handball;