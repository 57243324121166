import React from 'react';
import Footer from '../Footer/Footer';

function Features() {
    return (
        <>
            <section className='container'>
                <div className='row align-items-center '>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h1>Everything <br /> in one place</h1>
                        <ul>
                            <li>Planning & Management</li>
                            <li>Monitoring & Evaluations</li>
                            <li>Communication</li>
                            <li>Tactics & Diagrams</li>
                            <li>Video Analysis</li>
                            <li>Collections</li>
                            <li>Injury Management</li>
                        </ul>
                        <button className='btn btn-success mt-3'>SEE ALL FEATURES</button>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
                        <img src="images/everything.png" alt='image' width='500' className="img-fluid"/>
                    </div>
                </div>

                <div>
                    <h1 className='text-center ' style={{marginTop:"100px"}}>Planning & Management</h1>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
                        <img src="images/feature planning.png" alt='image' width='450' className="img-fluid"/>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <h4 >Build programmes for teams and individuals and plan sessions with ease</h4>
                            <ul>
                                <li><b>Calendar</b></li>
                                <li><b>Session Builder</b></li>
                                <li><b>Attendance</b></li>
                                <li><b>Session Templates</b></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div>
                    <h1 className='text-center mt-5'>Monitoring & Evaluations</h1>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <h4>Monitor testing data, information and athlete training loads. Make data-driven coaching decisions</h4>
                            <ul>
                                <li><b>Reports</b></li>
                                <li><b>Test Results</b></li>
                                <li><b>Forms & Questionnaires</b></li>
                            </ul> 
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12 mt-5'>
                        <img src="images/Monitor.png" alt='image' width='500' className="img-fluid"/>
                        </div>
                    </div>
                </div>

                <div>
                    <h1 className='text-center mt-5'>Communications</h1>
                    <div className='row align-items-center justify-content-between'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                        <img src="images/communication 2.png" alt='image' width='500'className="img-fluid" />
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <h4>Keep all communications with coaches and athletes in one place</h4>
                            <ul>
                                <li><b>Message Plateform</b></li>
                                <li><b>Share in a Second</b></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div>
                    <h1 className='text-center mt-5'>Tactics & Diagrams</h1>
                    <div className='row align-items-center'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <h4>Recreate game and training situations with sport specific diagrams and animations</h4>
                            <ul>
                                <li><b>ALIBTISAM Playbook</b></li>
                                <li><b>Sports Flexibility</b></li>
                            </ul>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                        <img src="images/Tactic.png" alt='image' width='350' className='mt-5 img-fluid'/>
                        </div>
                    </div>
                </div>


                <div>
                    <h1 className='text-center mt-5'>Video Analysis</h1>
                    <div className='row align-items-center justify-content-between'>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <img src= "images/video analysis.png" alt='image' width='500' className="img-fluid"/>
                        </div>
                        <div className='col-lg-6 col-md-6 col-sm-12'>
                            <h4>Analyse your training and match footage using a variety of analysis tools. Instantly share clips across your network</h4>
                            <ul>
                                <li><b>Video Analyser</b></li>
                                <li><b>Customised Tagging</b></li>
                                <li><b>Presentation Builder</b></li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="row text-white py-5">
                        <div>
                            <div className="card-body">
                                <div className="row text-center justify-content-center">
                                    <div className="col-auto">
                                        <div className="input-group-append">
                                            <button className="btn btn-success mt-4" type="button" id="button-addon2">         <b>TRY ALIBTISAM FOR FREE</b>
                                            </button>
                                            <p className="pl-0 ml-0 mt-4 text-dark">No
                                             credit card required.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default Features