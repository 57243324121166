import React from 'react';
import Footer from '../Footer/Footer';
import CommonPage from './CommonPage';

function Floorball() {
    return (
        <>
            <div className="container" id="Planning">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <h1 className="font-weight-bold"><b>For the love of developing athletes
                        </b> </h1>
                        <h5>Get your free ALIBTISAM  features overview</h5>
                        {/* <button type="button" className="btn btn-success"><b>Download
                            PDF</b><i className="fa fa-2x fa-file-o" aria-hidden="true"></i>
                        </button> */}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <img src="images/floor ball.jpg" alt="image1" width='400' className='mt-5 img-fluid' />
                    </div>
                </div>
            </div>
            <CommonPage />

            {/* <div className='container text-center'>
                <h1><b>We help teams succeed.</b></h1>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h4><b>FBC Plzeň</b></h4>
                        <p>“All work in our club is prepared in XPS. The connection between head coach and other trainers is amazing. XPS is first class software and we enjoy every minute working with it.”</p>
                        <img className='rounded-circle' width='50' src={jakubfloorballcoachImage} alt='Jeroen Baart Image' />
                        <h6><b>Jakub Šimek</b></h6>
                        <span>Head Coach</span>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h4><b>Swedish Floorball Federation</b></h4>
                        <p>“Sidelines software helps us very much in our development. Both in terms of the development of the game and the players, but also in the development of our coaches. Adding that to a very solution-oriented and accommodating partner makes us from the Swedish Floorball very happy.”</p>
                        <img className='rounded-circle' width='50' src={emilfloorballcoachImage} alt='Jorge Dabanch Image' />
                        <h6><b>Emil Risberg</b></h6>
                        <span>Head of Development</span>
                    </div>
                </div>
            </div> */}
            <Footer />
        </>
    )
}

export default Floorball