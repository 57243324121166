import React from 'react';
import Footer from '../Footer/Footer';
import CommonPage from './CommonPage';


function Volleyball() {
    return (
        <>
            <div className="container" id="Planning">
                <div className="row align-items-center justify-content-center">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                        <h1 className="font-weight-bold"><b>For the love of developing athletes
                        </b> </h1>
                        <h5>Get your free ALIBTISAM  features overview</h5>
                        {/* <button type="button" className="btn btn-success"><b>Download
                            PDF</b><i className="fa fa-2x fa-file-o" aria-hidden="true"></i>
                        </button> */}
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                    <img src="images/volly ball.jpg" alt="image1" width='400' className='mt-5 img-fluid' />
                    </div>
                </div>
            </div>

            <CommonPage />

            {/* <div className='container text-center'> 
                <h1><b>We help teams succeed.</b></h1>
                <div className='row'>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h4><b>Women's National Team, Sweden</b></h4>
                        <p>“For me, working with Sideline is fast, simple and clear. Excellent for team communication. I use it to plan all training and for having test results and other analysis at hand to be able to observe the individual development of players. XPS helps us keep everyone on the same page, our players are always up to date with what we're doing.”</p>
                        <img className='rounded-circle' width='50' src={macculamCoach} alt='Jeroen Baart Image' />
                        <h6><b>Johann Verstappen</b></h6>
                        <span>Coach</span>
                    </div>
                    <div className='col-lg-6 col-md-6 col-sm-12'>
                        <h4><b>Women's National Team, Sweden</b></h4> 
                        <p>“XPS is a very powerful tool for practice planning, game preparation and player education, both on international level as well as with local clubs.”</p>
                        <img className='rounded-circle' width='50' src={macculamCoach} alt='Jorge Dabanch Image' />
                        <h6><b>Ismo Peltoarvo</b></h6>
                        <span>Head Coach</span>
                    </div>
                </div>
            </div> */}

            <Footer />
        </>
    )
}

export default Volleyball