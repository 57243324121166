import React from 'react';
import Header from './Components/Header/Header';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.min.js';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Basketball from './Components/SportsModule/Basketball';
import Fieldhockey from './Components/SportsModule/Fieldhockey';
import Floorball from './Components/SportsModule/Floorball';
import Handball from './Components/SportsModule/Handball';
import Icehockey from './Components/SportsModule/Icehockey';
import Performance from './Components/SportsModule/Performance';
import Soccer from './Components/SportsModule/Soccer';
import Tennis from './Components/SportsModule/Tennis';
import Volleyball from './Components/SportsModule/Volleyball';
import Othersport from './Components/SportsModule/Othersport';
import Features from './Components/FeaturesModule/Features';
import Home from './Components/HomePage/Home';
import LoginForm from './Components/Form/LoginForm';
import SuperAdmin from './Components/Form/SuperAdmin';
import Admin from './Components/Form/Admin';
import Coach from './Components/Form/Coach';




function App() {
  return (
    <>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route path='/' element={<Home/>}/>
          <Route path='/basketball' element={<Basketball/>}/>
          <Route path='/fieldhockey' element={<Fieldhockey/>}/>
          <Route path='/floorball' element={<Floorball/>}/>
          <Route path='/handball' element={<Handball/>}/>
          <Route path='/icehockey' element={<Icehockey/>}/>
          <Route path='/performance' element={<Performance/>}/>
          <Route path='/soccer' element={<Soccer/>}/>
          <Route path='/tennis' element={<Tennis/>}/>
          <Route path='/volleyball' element={<Volleyball/>}/>
          <Route path='/othersport' element={<Othersport/>}/>
          <Route path='/features' element={<Features/>}/>
          <Route path='/LoginForm' element={<LoginForm/>}/>
          <Route path='/SuperAdmin' element={<SuperAdmin/>}/>
          <Route path='/Admin' element={<Admin/>}/>

          <Route path='/Coach' element={<Coach/>}/>



        </Routes>
      </BrowserRouter>
      
    </>
  )
}

export default App