import React from 'react';
import Footer from '../Footer/Footer';
import CommonPage from './CommonPage';

function Basketball() {
  return (
    <>
      <div className="container" id="Planning">
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-6 col-md-6 col-sm-6">
            <h1 className="font-weight-bold"><b>For the love of developing athletes
            </b> </h1>
            <h5 className='mt-5'>Get your free ALIBTISAM    features overview</h5>
            {/* <button type="button" className="btn btn-success mt-5"><b>Download
              PDF</b><i className="fa fa-2x fa-file-o" aria-hidden="true"></i>
            </button> */}
          </div>
          <div className="col-lg-6 col-md-6 col-sm-6">
            <img src="images/basketball.jpg" alt="image1" width='400' className='mt-5'className="img-fluid"/>
          </div>
        </div>
      </div> 
      
      <CommonPage />

{/* coach testimonial section start */}
      {/* <div className='container text-center'>
        <h1 className='mb-5'><b>We help teams succeed.</b></h1>
        <div className='row mt-5'>
          <div className='col-lg-6 col-md-6 col-sm-12'> 
            <h4><b>R. Antwerp H.C.</b></h4>
            <p>“XPS is a great tool to plan, analyze and communicate your training efforts for clubs and teams.”</p>
            <img className='rounded-circle' width='50' src={jeroenbaartCoach} alt='Jeroen Baart Image'/>
            <h6><b>Jeroen Baart</b></h6>
            <span>Head Coach</span>
          </div>
          <div className='col-lg-6 col-md-6 col-sm-12'>
            <h4><b>Junior F.C. Barcelona</b></h4>
            <p>“XPS is the perfect software for hockey clubs. All of your exercises, documentation, videos and statistics for your teams and players always available.”</p>
            <img className='rounded-circle' width='50' src={jorgedabacnchCoach} alt='Jorge Dabanch Image'/>
            <h6><b>Jorge Dabanch</b></h6>
            <span>Head Coach</span>
          </div>
        </div>
      </div> */}
      
      <Footer />
    </>
  )
}

export default Basketball;